import CompanyService from "@/services/companyService.js";
import router from "@/config/router/index";
function parseCustomDate(dateString) {
    const [day, month, year] = dateString.split('.');
    return new Date(`${year}-${month}-${day}`); // Convert to "YYYY-MM-DD"
}
const state = {
    companyBasicDetails: '',
    companyTenders: '',
    companyOrders: '',
    companyFounders: [
        {
            founderName: 'FAYZULLAYEV SHUXRAT NASIBULLAYEVIC',
            authorizedFund: 30000000000,
            shareHolder: 25
        },
        {
            founderName: 'ZIYAYEV FARRUX GOLIBSHEROVICH',
            authorizedFund: 30000000000,
            shareHolder: 25

        },
        {
            founderName: 'YAKUBOV TULKIN ABDUVAXIDOVICH',
            authorizedFund: 20000000000,
            shareHolder: 23.5
        },
        {
            founderName: 'USANOV ZAMIRJON USMANJANOVICH',
            authorizedFund: 10000000000,
            shareHolder: 14.5

        },
        {
            founderName: 'MURODOV FARRUX SHARIPOVICH',
            authorizedFund: 5000000000,
            shareHolder: 9.5

        },
        {
            founderName: 'KASIMOV JAMSHID XAMITOVICH',
            authorizedFund: 3000000000,
            shareHolder: 2.5

        }
    ],
    companyLicensies: '',
    companyLicensiesFilter: '',
    companyLicensiesFilterPagination: '',
    companyTradeMarks: '',
    tradeMarkDetails: '',
    registrationInfo: '',
    reportFile: '',
    companyBranches: '',
    licenseDetails: '',
    hasHistory:false,
    visitor: '',
    expired:false
}

const getters = {
    getCompanyBasicDetails(state) {
        return state.companyBasicDetails;
    },
    getCompanyBranches(state) {
        return state.companyBranches;
    },
    getCompanyFounders(state) {
        return state.companyFounders;
    },
    getCompanyTenders(state) {
        return state.companyTenders;
    },
    getCompanyOrders(state) {
        return state.companyOrders;
    },
    getCompanyLicensies(state) {
        return state.companyLicensies;
    },
    getCompanyTradeMarks(state) {
        return state.companyTradeMarks;
    },
    getTradeMarkDetails(state) {
        return state.tradeMarkDetails;
    },
    getReportFileUrl(state) {
        return state.reportFile;
    },
    getRegistrationInfo(state) {
        return state.registrationInfo;
    },
    getLicenseDetails(state) {
        return state.licenseDetails;
    },
    getHasHistory(state) {
        return state.hasHistory;
    },
    getVisitor(state){
        return state.visitor;
    },
    isDataExriped(state) {
        return state.expired;
    },
    getCompanyLicensiesFilter(state){
        return state.companyLicensiesFilter;
    },
    getCompanyLicensiesFilterPagination(state) {
        return state.companyLicensiesFilterPagination;
    }
}

const mutations = {
    clearTemp(state) {
        state.companyBasicDetails = '';
        state.companyTenders = '';
        state.companyOrders = '';
        state.companyFounders = '';
        state.companyLicensies = '';
        state.companyTradeMarks = '';
        state.tradeMarkDetails = '';
        state.registrationInfo = '';
        state.reportFile = '';
        state.companyBranches = '';
        state.licenseDetails = '',
        state.hasHistory = false
    },
    setCompanyBasicDetails(state, companyBasicDetails) {
        state.companyTenders = '';
        state.companyOrders = '';
        state.companyTradeMarks = '';
        state.companyBasicDetails = companyBasicDetails;
    },
    setCompanyBranches(state, branches) {
        state.companyBranches = branches;
    },
    setCompanyTenders(state, tenders) {
        state.companyTenders = tenders;
    },
    setCompanyOrders(state, orders) {
        state.companyOrders = orders;
    },
    setCompanyFounders(state, payload) {
        state.companyFounders = payload;
    },
    setCompanyLicensies(state, payload) {
        return state.companyLicensies = payload;
    },
    setCompanyTradeMarks(state, tradeMarks) {
        state.companyTradeMarks = tradeMarks;
    },
    setTradeMarkDetails(state, tradeMarkDetails) {
        state.tradeMarkDetails = tradeMarkDetails;
    },
    setReportFileUrl(state, url) {

        state.reportFile = process.env.VUE_APP_API_URL + url;
    },
    setRegistrationInfo(state, payload) {
        state.registrationInfo = payload;
    },
    setLicenseDetails(state, payload) {
        state.licenseDetails = state.companyLicensies.items?.find(x=>x.id == payload);
    },
    setCompanyLicensesFilter(state, payload) {
        let name = payload.name ? (name) =>  name.startsWith(payload.name) :(name)=> true;
        let startRegDate = payload.startRegDate ? (date) =>  new Date(date)> new Date(payload.startRegDate) :(date)=> true;
        let endRegDate = payload.endRegDate ? (date) =>  new Date(date)< new Date(payload.endRegDate) :(date)=> true;
        let startExpiryDate = payload.startExpiryDate ? (date) =>  new Date(date)> new Date(payload.startExpiryDate) :(date)=> true;
        let endExpiryDate = payload.endExpiryDate ? (date) =>  new Date(date)< new Date(payload.endExpiryDate) :(date)=> true;
        let regNumber = payload.regNumber ? (regNumber) => regNumber.startsWith(payload.regNumber) : (regNumber) => true;
        let licenseType = payload.licenseType !=0  ? (licenseType) =>{return payload.licenseType !=3 ? (licenseType == payload.licenseType) : (licenseType != 'ACTIVE' && licenseType != 'EXPIRED') } : (licenseType) => true;
        let companyLicensiesFilter = state.companyLicensies.items?.filter(x=> name(x.documentNameRu) &&
            startRegDate(x.registrationDate) && endRegDate(x.registrationDate) &&  startExpiryDate(x.expiryDate ?? new Date()) &&
            endExpiryDate(x.expiryDate ?? new Date()) && regNumber(x.registerNumber) && licenseType(x.status));
        let totalItems = companyLicensiesFilter?.length;
        state.companyLicensiesFilterPagination = {
            totalItems: totalItems,
            totalPages: Math.ceil(totalItems/payload.pageSize),

            pageNumber: payload.pageNumber,
            hasPrevious: payload.pageNumber > 1,
            hasNext: payload.pageNumber < Math.ceil(totalItems/payload.pageSize)
        };
        let skip = payload.pageSize * (payload.pageNumber - 1);
        let take = skip + payload.pageSize;
        state.companyLicensiesFilter = companyLicensiesFilter?.slice(skip, take);
    },
    setHasHistory(state, payload) {
        state.hasHistory = payload;
    },
    setVisitorInfo(state, payload){
        state.visitor = payload;
    },
    setIsDataExpired(state, payload){
        state.isDataExriped = payload;
    }
}

const actions = {
    async companyBasicDetails_api(contex, inn) {
        contex.commit('setCompanyBasicDetails', '');
        try {
            const response = await CompanyService.companyBasicDetails(inn);
            if (response.status == 200) {
                if (response.data) {
                    let responseData = response.data;
                    if (responseData.success) {
                        let companyDetails = responseData.data;
                        contex.commit('setCompanyBasicDetails', companyDetails);
                        contex.commit('setCompanyBranches', companyDetails.branchs ?? []);

                    }
                    else {
                        contex.commit('setIsProcess', false);
                        contex.commit('setErrorMessage', responseData.errorMessage);
                        router.push({ name: 'notFound' });
                    }
                }
                else {
                    contex.commit('setErrorMessage', response.data.Title);
                    router.push({ name: 'notFound' });

                }
            }
            else {
                //error page
                contex.commit('setErrorMessage', response.data.Title);
                router.push({ name: 'notFound' });
            }
        }
        catch (e) {
            contex.commit('setErrorMessage', e.response.data.Title);
            router.push({ name: 'notFound' });
        }

    },
    async companyBranches_api(contex, inn) {
        contex.commit('setCompanyBranches', '');
        try {

            const response = await CompanyService.getCompanyBranches(inn);
            if (response.status == 200) {
                if (response.data) {
                    let responseData = response.data;
                    if (responseData.success) {
                        let companyDetails = responseData.data;
                        contex.commit('setCompanyBranches', companyDetails);
                    }
                    else {
                        contex.commit('setIsProcess', false);
                        contex.commit('setErrorMessage', responseData.errorMessage);
                        router.push({ name: 'notFound' });
                    }
                }
                else {
                    contex.commit('setErrorMessage', response.data.Title);
                    router.push({ name: 'notFound' });

                }
            }
            else {
                //error page
                contex.commit('setErrorMessage', response.data.Title);
                router.push({ name: 'notFound' });
            }
        }
        catch (e) {
            contex.commit('setErrorMessage', e.response.data.Title);
            router.push({ name: 'notFound' });
        }

    },
    async companyFounders_api(contex, inn) {
        contex.commit('setCompanyFounders', '');
        try {
            const response = await CompanyService.companyFounders(inn);
            if (response.status == 200) {
                if (response.data) {
                    let responseData = response.data;
                    if (responseData.success) {
                        let companyDetails = responseData.data;
                        contex.commit('setCompanyFounders', companyDetails);
                        contex.commit('setHasHistory', true);
                        
                    }
                    else if (responseData.StatusCode == 429) {
                        contex.commit('setCompanyFounders', "Payment");
    
                        contex.commit('setErrorMessage', 'Ваш лимит на сегодня закончился');
    
                    }
                    else if (responseData.StatusCode == 401) {
                        contex.commit('setCompanyFounders', "Payment");
                    }
                    else if (responseData.StatusCode == 402) {
                        contex.commit('setCompanyFounders', "Payment");
                        const sonucModal= document.getElementById('LowBalanceModal');
                        const modalEl = new bootstrap.Modal(sonucModal);
                        modalEl.show();
                    }
                    else if (responseData.StatusCode == 451){
                        let url  = responseData.RedirectUrl;
                        url +=(process.env.VUE_APP_URL + router.currentRoute.value.fullPath.substring(1));
                        window.location = url;
                    }
                    else {
                        contex.commit('setIsProcess', false);
                        contex.commit('setErrorMessage', responseData.errorMessage);
                        router.push({ name: 'notFound' });
                    }
                }
                else {
                    contex.commit('setErrorMessage', response.data.Title);
                    router.push({ name: 'notFound' });

                }
            }
            else {
                //error page
                contex.commit('setErrorMessage', response.data.Title);
                router.push({ name: 'notFound' });
            }
        }
        catch (e) {
            contex.commit('setErrorMessage', e.response.data.Title);
            router.push({ name: 'notFound' });
        }

    },
    async companyTenders_api(contex, inn) {
        contex.commit('setCompanyTenders', '');
        const response = await CompanyService.companyTenders(inn);
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    let companyDetails = responseData.data;
                    contex.commit('setCompanyTenders', companyDetails);
                }
                else {
                    contex.commit('setErrorMessage', responseData.errorMessage);
                }
            }
            else {
                contex.commit('setErrorMessage', response);
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    },
    async companyOrders_api(contex, inn) {
        contex.commit('setCompanyOrders', '');
        const response = await CompanyService.companyOrders(inn);
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    let companyDetails = responseData.data;
                    contex.commit('setCompanyOrders', companyDetails);
                }
                else {
                    contex.commit('setErrorMessage', responseData.errorMessage);
                }
            }
            else {
                contex.commit('setErrorMessage', response);
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    },
    async companyLicensies_api(contex, obj) {
        contex.commit('setCompanyLicensies', '');
        let response = '';
        if (obj.pinfl != undefined) {
            response = await CompanyService.getLicensiesIndividuals(obj);
        }
        else {
            response = await CompanyService.getLicensiesLegal(obj);
        }
        if (response.status == 200) {
                if (response.data) {
                    let responseData = response.data;
                    if (responseData.success) {

                        let companyDetails
                         = responseData.data;
                        contex.commit('setCompanyLicensies',{items: companyDetails});
                        contex.commit('setHasHistory', true);

                    }
                    else if (responseData.StatusCode == 429) {
                        contex.commit('setCompanyLicensies', "Payment");
        
                        contex.commit('setErrorMessage', 'Ваш лимит на сегодня закончился');
        
                    }
                    else if (responseData.StatusCode == 401) {
                        contex.commit('setCompanyLicensies', "Payment");
                    }
                    else if (responseData.StatusCode == 402) {
                        contex.commit('setCompanyLicensies', "Payment");
                        const sonucModal= document.getElementById('LowBalanceModal');
                        const modalEl = new bootstrap.Modal(sonucModal);
                        modalEl.show();
                    }
                    else if (responseData.StatusCode == 451){
                        let url  = responseData.RedirectUrl;
                        url +=(process.env.VUE_APP_URL + router.currentRoute.value.fullPath.substring(1));
                        window.location = url;
                    }
                    else {
                        contex.commit('setErrorMessage', responseData.errorMessage);
                    }
                }
                else {
                    contex.commit('setErrorMessage', response);
                }
        }

        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    },
    async registrationInfo_api(contex, inn) {
        contex.commit('setRegistrationInfo', '');
        let response = '';
        if (inn.length > 9) {

            response = await CompanyService.getIndividualRegistrationInfo(inn);
        }
        else {
            response = await CompanyService.getCompanyRegistrationInfo(inn);
        }
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    let companyDetails = responseData.data;
                    contex.commit('setRegistrationInfo', companyDetails); 
                    contex.commit('setHasHistory', true);

                }
                else if (responseData.StatusCode == 429) {
                    contex.commit('setRegistrationInfo', "Payment");
    
                    contex.commit('setErrorMessage', 'Ваш лимит на сегодня закончился');    
                }
                else if (responseData.StatusCode == 401) {
                    contex.commit('setRegistrationInfo', "Payment");
                    
                }
                else if (responseData.StatusCode == 402) {
                    contex.commit('setRegistrationInfo', "Payment");
                    const sonucModal= document.getElementById('LowBalanceModal');
                    const modalEl = new bootstrap.Modal(sonucModal);
                    modalEl.show();
                }
                else if (responseData.StatusCode == 451){
                    let url  = responseData.RedirectUrl;
                    url +=(process.env.VUE_APP_URL + router.currentRoute.value.fullPath.substring(1));
                    window.location = url;
                }
                else {
                    contex.commit('setErrorMessage', responseData.errorMessage);
                }
            }
            else {
                contex.commit('setErrorMessage', response);
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    },
    async licenseDetails_api(contex, id) {
        contex.commit('setLicenseDetails', '');
        const response = await CompanyService.getLicenseDetails(id);
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    let companyDetails = responseData.data;
                    if (companyDetails.data) {
                        contex.commit('setLicenseDetails', companyDetails.data[0]);
                    }
                    else {
                        contex.commit('setErrorMessage', responseData.errorMessage);
                        router.push({ name: 'notFound' })
                    }
                }
                else {
                    contex.commit('setErrorMessage', responseData.errorMessage);
                }
            }
            else {
                contex.commit('setErrorMessage', response);
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    },
    async companyTradeMarks_api(contex, inn) {
        contex.commit('setCompanyTradeMarks', '');
        const response = await CompanyService.companyTradeMarks(inn);
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    let companyDetails = responseData.data;
                    contex.commit('setCompanyTradeMarks', companyDetails);
                }
                else {
                    contex.commit('setErrorMessage', responseData.errorMessage);
                }
            }
            else {
                contex.commit('setErrorMessage', response);
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    },
    async tradeMarkDetails_api(contex, id) {
        contex.commit('setTradeMarkDetails', '');
        const response = await CompanyService.tradeMark(id);
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    let tradeMarkDetails = responseData.data;
                    contex.commit('setTradeMarkDetails', tradeMarkDetails);
                }
                else {
                    contex.commit('setErrorMessage', responseData.errorMessage);
                }
            }
            else {
                contex.commit('setErrorMessage', response);
                router.push({ name: 'notFound' });
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
            router.push({ name: 'notFound' });

        }
    },
    async generateReportFile_api(contex, inn) {
        contex.commit('setReportFileUrl', '');
        try {

            const response = await CompanyService.generateReportFile(inn, 2);
            if (response.status == 200) {
                if (response.data) {
                    let responseData = response.data;
                    if (responseData.success) {
                        let url = responseData.data;
                        contex.commit('setReportFileUrl', url);
                    }
                    else {
                        contex.commit('setIsProcess', false);
                        contex.commit('setErrorMessage', responseData.errorMessage);
                        router.push({ name: 'notFound' });
                    }
                }
                else {
                    contex.commit('setErrorMessage', response.data.errorMessage);
                    router.push({ name: 'notFound' });

                }
            }
            else {
                //error page
                contex.commit('setErrorMessage', response.data.errorMessage);
                router.push({ name: 'notFound' });
            }
        }
        catch (e) {
            contex.commit('setErrorMessage', e.response.data.errorMessage);
            router.push({ name: 'notFound' });
        }

    },
    async companyBasicDetailsHistory_api(contex, inn) {
        contex.commit('setCompanyBasicDetails', '');
        try {
            const response = await CompanyService.companyBasicDetailsHistory(inn);
            if (response.status == 200) {
                if (response.data) {
                    let responseData = response.data;
                    if (responseData.success) {
                        let companyDetails = responseData.data;
                        contex.commit('setCompanyBasicDetails', companyDetails);
                    }
                    else {
                        contex.commit('setIsProcess', false);
                        contex.commit('setErrorMessage', responseData.errorMessage);
                        router.push({ name: 'notFound' });
                    }
                }
                else {
                    contex.commit('setErrorMessage', response.data.Title);
                    router.push({ name: 'notFound' });

                }
            }
            else {
                //error page
                contex.commit('setErrorMessage', response.data.Title);
                router.push({ name: 'notFound' });
            }
        }
        catch (e) {
            contex.commit('setErrorMessage', e.response.data.Title);
            router.push({ name: 'notFound' });
        }

    },
    async companyFoundersHistory_api(contex, inn) {
        try {
            const response = await CompanyService.companyFoundersHistory(inn);
            if (response.status == 200) {
                if (response.data) {
                    let responseData = response.data;
                    if (responseData.success) {
                        let companyDetails = responseData.data;
                        contex.commit('setCompanyFounders', companyDetails);
                        contex.commit('setHasHistory', true);
                    }
                }
                else {
                    contex.commit('setErrorMessage', response.data.Title);
                    router.push({ name: 'notFound' });

                }
            }
            else {
                //error page
                contex.commit('setErrorMessage', response.data.Title);
                router.push({ name: 'notFound' });
            }
        }
        catch (e) {
            contex.commit('setErrorMessage', e.response.data.Title);
            router.push({ name: 'notFound' });
        }

    },
    async companyLicensiesHistory_api(contex, obj) {
        contex.commit('setCompanyLicensies', '');
        let response = '';
        if (obj.pinfl != undefined) {
            response = await CompanyService.getLicensiesIndividualsHistory(obj);
        }
        else {
            response = await CompanyService.getLicensiesLegalHistory(obj);
        }
        if (response.status == 200) {
                if (response.data) {
                    let responseData = response.data;
                    if (responseData.success) {
                        let companyDetails = responseData.data;
                        contex.commit('setCompanyLicensies', companyDetails);
                        contex.commit('setHasHistory', true);
                    }
                    else {
                        contex.commit('setCompanyLicensies', "Payment");
                    }
                }
                else {
                    contex.commit('setErrorMessage', response);
                }
        }

        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    },
    async registrationInfoHistory_api(contex, inn) {
        contex.commit('setRegistrationInfo', '');
        let response = '';
        if (inn.length > 9) {
            response = await CompanyService.getIndividualRegistrationInfoHistory(inn);
        }
        else {
            response = await CompanyService.getCompanyRegistrationInfoHistory(inn);
        }
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    let companyDetails = responseData.data;
                    contex.commit('setRegistrationInfo', companyDetails); 
                    contex.commit('setHasHistory', true);

                }
                else {
                    contex.commit('setRegistrationInfo', 'Payment'); 

                }
            }
            else {
                contex.commit('setErrorMessage', response);
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    },
    async visitorInfo_api(contex) {
        contex.commit('setVisitorInfo', '');
        let  response = await CompanyService.getVisitorFinanceInfo();
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                let companyDetails = responseData;
                    contex.commit('setVisitorInfo', companyDetails); 

            }
            else {
                contex.commit('setErrorMessage', response);
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    },
    async companyData_api(contex, obj){
        contex.commit('clearTemp');
        let  response = await CompanyService.getCompanyData(obj);
        if (response.status == 200) {
            if (response.data) {
                 response.headers['x-is-expired'];
                contex.commit('setCompanyBasicDetails', response.headers['x-is-expired']?? false);
                let responseData = response.data;
                if (responseData.success) {
                    contex.commit('setCompanyBasicDetails', responseData.data);
                    contex.commit('setCompanyBranches', responseData.data.branchs ?? []);
                    contex.commit('setCompanyFounders', responseData.data.founders?? []);
                    contex.commit('setCompanyLicensies',{items: responseData.data.licenses ?? []});
                    if (responseData.data?.certificateNumber && responseData.data?.registryNumber) {
                        contex.commit('setHasHistory', true);
                    }
                }else if (responseData.StatusCode == 429) {
                    contex.commit('setErrorMessage', 'Ваш лимит на сегодня закончился');    
                }
                else if (responseData.StatusCode == 401) {
                    let qs = `response_type=code&client_id=FakturaORGClientId&redirect_uri=` + window.location;
                    window.location.href = `https://account.faktura.uz/Authorize?${qs}`;
                }
                else if (responseData.StatusCode == 402) {
                    contex.commit('setRegistrationInfo', "Payment");
                    const sonucModal= document.getElementById('LowBalanceModal');
                    const modalEl = new bootstrap.Modal(sonucModal);
                    modalEl.show();
                }
                else if (responseData.StatusCode == 451){
                    let url  = responseData.RedirectUrl;
                    url +=(process.env.VUE_APP_URL + router.currentRoute.value.fullPath.substring(1));
                    window.location = url;
                }
                else {
                    contex.commit('setErrorMessage', responseData.errorMessage);
                }

            }
            else {
                contex.commit('setErrorMessage', response);
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    }
}


export default {
    state,
    getters,
    mutations,
    actions
}